import Product from '../types/Product';
import { IProductConfiguration } from '../types/ProductConfiguration';

const getAvailableFiltersByProduct = (product: Product) => {
  const filtersMap = {};

  if (product && product.configurable_options) {
    product.configurable_options.forEach((configurableOption) => {
      const type = configurableOption.attribute_code;
      const filterVariants = configurableOption.values.map(({ value_index, label }) => {
        return { id: value_index, label, attribute_code: type };
      });
      filtersMap[type] = filterVariants;
    });
  }

  return filtersMap;
};

const getSelectedFiltersByProduct = (product: Product, configuration: IProductConfiguration) => {
  if (!configuration) {
    return null;
  }

  const selectedFilters = {};

  if (configuration && product) {
    Object.keys(configuration).map((filterType) => {
      const filter = configuration[filterType];

      selectedFilters[filterType] = {
        id: filter.id,
        label: filter.label,
        attribute_code: filterType,
      };
    });
  }

  return selectedFilters;
};

export { getAvailableFiltersByProduct, getSelectedFiltersByProduct };
