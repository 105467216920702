import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { FreeShippingMethodsStore } from 'theme/stores/freeShippingMethods/freeShippingMethods';
import { FormatCurrency } from '@zento-lib/components/i18n/FormatCurrency';

import type { IFreeShippingMethods } from './FreeShippingMethods.d';
import style from './style.scss';

/**
 * Free shipping methods
 *
 * Free shipping methods component rendering remaining value for free shipping
 */
@Component({})
export class FreeShippingMethods extends BaseComponent<IFreeShippingMethods, any> {
  private static T = {
    freeShippingMessage: 'free_shipping_message',
    freeShippingSuccessMessage: 'free_shipping_success_message',
    freeShippingFor: 'free_shipping_for',
    freeShippingAnd: 'free_shipping_and',
  };

  /**
   * Determines if cart has products
   */
  @Prop({ type: Number, required: true })
  countProducts: number;

  private freeShippingStore = new FreeShippingMethodsStore();

  /**
   * Fetch free shipping methods
   */
  async fetchFreeShippingMethods() {
    return this.freeShippingStore.fetchFreeShippingMethods(this.$store.getters['cart/getCartToken']);
  }

  /**
   * Fetch cart usp on server side
   */
  public serverPrefetch() {
    return this.fetchFreeShippingMethods();
  }

  async mounted() {
    if (this.countProducts > 0) {
      await this.fetchFreeShippingMethods();
    }
  }

  render() {
    let prices = 0;
    this.freeShippingStore.freeShippingMethods.map((m) => {
      prices = parseFloat(m.value);
    });

    return (
      <div>
        {this.countProducts > 0 ? (
          <div class={style.freeShippingMessage}>
            {this.freeShippingStore.freeShippingMethods.length && prices > 0 ? (
              <div class={style.shipping}>
                <p>
                  {this.getTranslation({ id: FreeShippingMethods.T.freeShippingMessage })}
                  {this.freeShippingStore.freeShippingMethods.map((m) => {
                    const price = parseFloat(m.value);

                    return (
                      <span>
                        {price !== 0 ? (
                          <span>
                            <strong>
                              <FormatCurrency value={price} key='free-shipping-price' />
                            </strong>
                            <span>{this.getTranslation({ id: FreeShippingMethods.T.freeShippingFor })}</span>
                            <strong> {m.label} </strong>
                            <span>{this.getTranslation({ id: FreeShippingMethods.T.freeShippingAnd })}</span>
                          </span>
                        ) : null}
                      </span>
                    );
                  })}
                </p>
              </div>
            ) : null}

            {this.freeShippingStore.freeShippingMethods.map((m) => {
              const price = parseFloat(m.value);

              return (
                <div
                  class={{
                    [style.shipping]: true,
                    [style.shippingSucces]: true,
                    [style.freeShipping]: price === 0,
                  }}>
                  {price === 0 ? (
                    <div>
                      <h4>{m.label}</h4>
                      <p>{this.getTranslation({ id: FreeShippingMethods.T.freeShippingSuccessMessage })}</p>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
