import { Component, BaseComponent, Watch, namespace, Prop } from '@zento-lib/components';
import { Microcart } from 'theme/stores/cart/components/Microcart';
import { CartStore } from 'theme/stores/cart/cart';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import { IVSFCart, KEY as CartKey } from 'theme/@types/vsf/stores/cart';

import { Button } from '../../../atom/Button/Button';
import type { AppliedCoupon } from '../../types/CouponType';

import type { ICartCoupon } from './Coupon.d';
import style from './style.scss';

interface ICartCouponMixin {
  applyCoupon: (code: string) => Promise<any>;
  removeCoupon: () => Promise<boolean>;
  appliedCoupon: AppliedCoupon;
}

const cartStore = namespace<IVSFCart>(CartKey);

/**
 * Cart Coupon
 *
 * Allows end users to add a cart discount/coupon
 */
@Component({
  mixins: [Microcart],
})
@Form()
export class CartCoupon extends BaseComponent<ICartCoupon, ICartCouponMixin> {
  private static T = {
    addPromoCode: 'cart_promo_code_add_btn_label',
    cartIncorrectCouponCode: 'cart_incorrect_coupon_code',
    cartCouponInputLabel: 'cart_coupon_input_label',
    cartCouponBtnLabel: 'cart_coupon_btn_label',
    cartIncorrectActionLabel: 'notification_action',
    cartCorrectCouponCode: 'cart_correct_coupon_code',
    cartCorrectActionLabel: 'notification_action',
  };

  /**
   * Free gift product sku
   */
  @Prop({ type: String, default: '' })
  freeGiftProductSku?: string;

  @cartStore.Getter('getCoupon')
  getCoupon: AppliedCoupon | false;

  protected cartStore = new CartStore();

  @Watch('getCoupon')
  couponIsApplied() {
    if (this.getCoupon) {
      this.broadcast('analytics/cart-promo-click', {
        promo: { code: this.getCoupon.code, discount: this.getCoupon.discount },
      });
      this.broadcast('analytics/cart-promo-click-custom', {
        promo: { code: this.getCoupon.code, discount: this.getCoupon.discount },
      });
    }
  }

  data() {
    return {
      couponCode: '',
      addCouponPressed: false,
      couponData: false,
    };
  }

  beforeMount() {
    this.setCoupon = this.setCoupon.bind(this);
    this.addDiscountCoupon = this.addDiscountCoupon.bind(this);
  }

  addDiscountCoupon() {
    this.$data.addCouponPressed = true;
  }

  clearCoupon() {
    this.extended.removeCoupon();
    this.$data.addCouponPressed = false;
  }

  async setCoupon() {
    this.$data.couponData = true;
    const couponApplied = await this.extended.applyCoupon(this.$data.couponCode);
    this.$data.addCouponPressed = false;

    if (this.$data.couponCode === '') {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.getTranslation({ id: CartCoupon.T.cartIncorrectCouponCode }),
        action1: { label: this.getTranslation({ id: CartCoupon.T.cartIncorrectActionLabel }) },
      });
    } else {
      if (couponApplied === true) {
        this.$data.couponCode = '';
        this.cartStore.couponCode = '';

        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.getTranslation({ id: CartCoupon.T.cartCorrectCouponCode }),
          action1: { label: this.getTranslation({ id: CartCoupon.T.cartCorrectActionLabel }) },
        });
      } else {
        if (!this.extended.$config.zento.cart.enableCouponForm) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'warning',
            message: this.getTranslation({ id: couponApplied }),
            action1: { label: this.getTranslation({ id: CartCoupon.T.cartIncorrectActionLabel }) },
          });
        } else {
          this.cartStore.couponCode = couponApplied;
        }
      }

      if (this.freeGiftProductSku) {
        await this.$store.dispatch('cart/syncFreeGift');
      }
    }

    this.$data.couponData = false;
  }

  render() {
    const enableCouponForm = this.extended.$config.zento.cart.enableCouponForm;

    return (
      <div class={style.cartCouponSection}>
        {this.$data.couponData ? <div class={style.loading} /> : null}
        {!this.$data.couponData ? (
          <div
            class={{
              [style.cartCouponWrapper]: true,
              [style.enableCouponForm]: enableCouponForm,
            }}>
            {!enableCouponForm && !this.$data.addCouponPressed ? (
              <Button
                styleType={this.extended.$config.zento.cart.couponButtonStyle}
                handler={this.addDiscountCoupon}
                class={style.cartCouponAddBtn}>
                {this.getTranslation({ id: CartCoupon.T.addPromoCode })}
              </Button>
            ) : (
              [
                <div class={style.cartCouponInput}>
                  <Input
                    state={this.$data}
                    valueKeeper='couponCode'
                    name='couponCode'
                    validateOn='input'
                    validation={true}
                    slotPosition={true}
                    customMessage={this.cartStore.couponCode}
                    pattern='alphanumspecialchar'
                    key={'cart-coupon'}
                    ref='couponCode'
                    dataTestId='couponCode'>
                    <span slot='label'>{this.getTranslation({ id: CartCoupon.T.cartCouponInputLabel })}</span>
                  </Input>
                </div>,
                <Button
                  disabled={this.$data.couponCode.length === 0}
                  styleType={this.extended.$config.zento.cart.couponButtonSubmitStyle}
                  handler={this.setCoupon}
                  class={style.cartCouponButton}
                  dataTestId='couponCodeSubmit'>
                  {this.getTranslation({ id: CartCoupon.T.cartCouponBtnLabel })}
                </Button>,
              ]
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
