import { onlineHelper } from '@vue-storefront/core/helpers';
import { Component, BaseComponent, namespace, Prop, Watch } from '@zento-lib/components';
import { getCurrentConfig } from 'theme/stores/extend-cart/helpers/getCurrentConfig';
import { isOptionAvailableAsync } from 'theme/stores/catalog/helpers/index';
import { getAvailableFiltersByProduct } from 'theme/stores/catalog/helpers/filters';
import { Form } from 'theme/components/Form/Form';
import { NumericInput } from 'theme/components/Form/NumericInput';
import { IExtendCart, KEY as ExtendCartKey } from 'theme/@types/zento/stores/cartExtend';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import { Modal } from '../../../organism/Modal/Modal';
import type { SwatchOption } from '../../types/SwatchOptionsTypes';
import type { FilterType } from '../../types/FilterType';
import { MainImage } from '../../../atom/MainImage/MainImage';
import { CartProductPrice } from '../../../atom/Cart/Product/Price/Price';
import { Button } from '../../../atom/Button/Button';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { IEditModal } from './Modal.d';
import style from './style.scss';

const cartExtendStore = namespace<IExtendCart>(ExtendCartKey);
const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Cart Product Edit Modal
 *
 * Allows end users edit currently selected product from cart
 */
@Component({})
@Form()
export class EditModal extends BaseComponent<IEditModal, unknown> {
  private static T = {
    modalHeaderLabel: 'product_cart_edit_modal_header_label',
    cartProductSkuLabel: 'product_sku_label',
    updateItemBtnLabel: 'update_cart_item_btn_label',
  };

  /**
   * Determines if quantity input manipulation has button controls
   */
  @Prop({ type: Boolean, default: true })
  controls?: boolean;

  @cartExtendStore.Getter('getEditingProduct')
  private getEditingProduct: IExtendCart['state']['editMode']['product'];

  @cartExtendStore.Getter('isEditMode')
  private isEditMode: IExtendCart['state']['editMode']['product'];

  @cartExtendStore.Getter('getSelectedOptions')
  private getSelectedOptions: IExtendCart['state']['editMode']['selectedOptions'];

  @cartExtendStore.Getter('getEditingQty')
  private getEditingQty: IExtendCart['state']['editMode']['qty'];

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  @Watch('getEditingProduct.id', { deep: true, immediate: true })
  toggleModal() {
    if (this.getEditingProduct === null) {
      this.broadcast('modal-close', 'modal-edit-cart-item');
    } else {
      setTimeout(() => this.broadcast('modal-show', 'modal-edit-cart-item'), 0);
    }
  }

  @Watch('getEditingProduct.qty', { deep: true })
  updateQuantity() {
    if (this.getEditingProduct) {
      this.$store.dispatch('extendCart/editModeSetQty', { qty: this.getEditingProduct.qty });
    }
  }

  beforeMount() {
    this.closeEditMode = this.closeEditMode.bind(this);
  }

  render() {
    if (!this.getEditingProduct) {
      return null;
    }

    const product = this.getEditingProduct;
    const stock = product.stock;
    const minQty = stock ? (parseFloat(stock.min_sale_qty) > 0 ? parseFloat(stock.min_sale_qty) : 1) : 1;
    const maxQty = stock ? (parseFloat(stock.max_sale_qty) > 0 ? parseFloat(stock.max_sale_qty) : Infinity) : Infinity;

    return (
      <div class={style.editWrapper}>
        <Modal name='modal-edit-cart-item' hasOverlay={false} width={1024} class={style.modalWrapper}>
          <h3 slot='header' class={style.modalTitle} key='modal-edit-item-header'>
            {this.getTranslation({ id: EditModal.T.modalHeaderLabel })}
            <span onClick={this.closeEditMode} class={style.closeEditModal} />
          </h3>
          <div slot='content' class={style.modalContent} key='modal-edit-item-content'>
            <div class={style.editItemImgWrapper} key='edit-item-img-wrapper'>
              <MainImage
                image={this.image}
                alt={product.name}
                width={this.productThumbnail.width}
                height={this.productThumbnail.height}
                tabletWidth={this.productThumbnail.tabletWidth}
                tabletHeight={this.productThumbnail.tabletHeight}
                desktopWidth={this.productThumbnail.desktopWidth}
                desktopHeight={this.productThumbnail.desktopHeight}
              />
            </div>

            <div class={style.editItemInfoWrapper} key='edit-item-info-wrapper'>
              <div class={style.editItemInfo} key='edit-item-info'>
                <InnerHTML contents={product.name} class={style.editItemName} key='edit-item-name' />

                <span class={style.cartProductSku} data-testId='cartEditProductSku' key='cart-edit-product-sku'>
                  {this.getTranslation({ id: EditModal.T.cartProductSkuLabel })}:<span>{product.sku}</span>
                </span>

                <CartProductPrice
                  product={product}
                  isOnline={this.isOnline}
                  displayItemDiscounts={this.extended.$config.cart.displayItemDiscounts}
                  class={style.editItemPrice}
                  key='cart-edit-product-price'
                />
              </div>

              {!this.isMobile
                ? [
                    <div class={style.productSwatches}>
                      {product.configurable_options.map((option, index) => {
                        return (
                          <div key={index} class={style.productSwatchesGroup}>
                            <div class={style.productSwatchesLabel} key={option.id}>
                              {Object.keys(this.$store.state.attribute.list_by_code).length
                                ? this.$store.state.attribute.list_by_code[option.attribute_code]?.frontend_label
                                : option.label}
                            </div>
                            <div class={style.productSwatchesWrapper} key={option.id + '-option'}>
                              {(this.getAvailableFilters || { [option.attribute_code]: [] })[option.attribute_code].map(
                                (filter: SwatchOption) => {
                                  const productActiveFilter =
                                    parseInt(filter.id) ===
                                    parseInt(this.getSelectedOptions[filter.attribute_code]?.id);

                                  return (
                                    <div
                                      class={{
                                        // TODO: refine this section with dynamically filter code types - PRIORITISE once swatch module it's finished
                                        [style.inlineElm]: true,
                                        [style.active]: productActiveFilter,
                                        [style.unavailable]: !this.isOptionAvailable(filter),
                                      }}
                                      aria-label={filter.label}
                                      key={filter.label}>
                                      <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label>
                                      {/* TODO: adjust later on */}
                                      {/* {option.attribute_code.indexOf('color') === 0 ? ( */}
                                      {/* <label onClick={() => this.editModeSetFilters(filter)}> */}
                                      {/* <i style={{ background: backgroundColor }}></i> */}
                                      {/* {filter.label} */}
                                      {/* </label> */}
                                      {/* ) : /(size|shoes|marime)/g.test(option.attribute_code.toLowerCase()) ? ( */}
                                      {/* <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label> */}
                                      {/* ) : !/(size|shoes|marime)/g.test(option.attribute_code.toLowerCase()) ? ( */}
                                      {/* <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label> */}
                                      {/* ) : null} */}
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>,

                    this.controls ? (
                      <NumericInput
                        state={product}
                        valueKeeper='qty'
                        name='qty'
                        required={true}
                        step={
                          stock && stock.qty_increments && parseFloat(stock.qty_increments) >= 1
                            ? parseFloat(stock.qty_increments)
                            : 1
                        }
                        min={minQty}
                        max={maxQty}
                        validateOn='input'
                        validation={false}
                        controls={this.controls}
                        showDecrement={product.qty > 1}
                        slotPosition={false}
                        cartProduct={true}
                        class={style.cartProductNumericBtn}
                        key='cart-item-input-controls'
                      />
                    ) : null,

                    <Button
                      styleType='primary'
                      handler={this.updateProductVariant}
                      class={style.editCartItemBtn}
                      key='update-item-btn'>
                      {this.getTranslation({ id: EditModal.T.updateItemBtnLabel })}
                    </Button>,
                  ]
                : null}
            </div>

            {this.isMobile
              ? [
                  <div class={style.productSwatches}>
                    {product.configurable_options.map((option, index) => {
                      return (
                        <div key={index} class={style.productSwatchesGroup}>
                          <div class={style.productSwatchesLabel} key={option.id}>
                            {option.label}
                          </div>
                          <div class={style.productSwatchesWrapper} key={option.id + '-option'}>
                            {(this.getAvailableFilters || { [option.attribute_code]: [] })[option.attribute_code].map(
                              (filter: SwatchOption) => {
                                const productActiveFilter =
                                  parseInt(filter.id) === parseInt(this.getSelectedOptions[filter.attribute_code]?.id);

                                // const backgroundColor =
                                //   filter.label === 'Pink-Gold'
                                //     ? 'pink'
                                //     : filter.label === 'Rose-Gold'
                                //     ? 'gold'
                                //     : filter.label;

                                return (
                                  <div
                                    class={{
                                      [style.inlineElm]: /(size|shoes|marime|marimi)/g.test(
                                        option.attribute_code.toLowerCase(),
                                      ),
                                      [style.active]: productActiveFilter,
                                      [style.colorElem]: option.attribute_code.indexOf('color') === 0,
                                      [style.unavailable]: !this.isOptionAvailable(filter),
                                    }}
                                    aria-label={filter.label}
                                    key={filter.label}>
                                    <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label>
                                    {/* TODO: adjust later on */}
                                    {/* {option.attribute_code.indexOf('color') === 0 ? ( */}
                                    {/* <label onClick={() => this.editModeSetFilters(filter)}> */}
                                    {/* <i style={{ background: backgroundColor }}></i> */}
                                    {/* {filter.label} */}
                                    {/* </label> */}
                                    {/* ) : /(size|shoes|marime)/g.test(option.attribute_code.toLowerCase()) ? ( */}
                                    {/* <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label> */}
                                    {/* ) : !/(size|shoes|marime)/g.test(option.attribute_code.toLowerCase()) ? ( */}
                                    {/* <label onClick={() => this.editModeSetFilters(filter)}>{filter.label}</label> */}
                                    {/* ) : null} */}
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>,

                  this.controls ? (
                    <NumericInput
                      state={product}
                      valueKeeper='qty'
                      name='qty'
                      required={true}
                      step={
                        stock && stock.qty_increments && parseFloat(stock.qty_increments) >= 1
                          ? parseFloat(stock.qty_increments)
                          : 1
                      }
                      min={minQty}
                      max={maxQty}
                      validateOn='input'
                      validation={false}
                      controls={this.controls}
                      showDecrement={product.qty > 1}
                      slotPosition={false}
                      cartProduct={true}
                      class={style.cartProductNumericBtn}
                      key='cart-item-input-controls'
                    />
                  ) : null,

                  <Button
                    styleType='primary'
                    handler={this.updateProductVariant}
                    class={style.editCartItemBtn}
                    key='update-item-btn'>
                    {this.getTranslation({ id: EditModal.T.updateItemBtnLabel })}
                  </Button>,
                ]
              : null}
          </div>
        </Modal>

        {this.isEditMode ? (
          <div
            onClick_capture={this.closeEditMode}
            class={{ [style.editOverlay]: true, [style.editOverlayOpened]: this.isEditMode }}
          />
        ) : null}
      </div>
    );
  }

  /**
   * Returns an object containing current cart product filters
   */
  private get getAvailableFilters() {
    return getAvailableFiltersByProduct(this.getEditingProduct);
  }

  /**
   * Returns an object with current selected filters from cart product
   */
  private get swatchConfiguration() {
    return getCurrentConfig(this.getEditingProduct);
  }

  /**
   * Get product thumbnail
   */
  get productThumbnail() {
    return this.extended.$config.zento.images.productsThumbnails;
  }

  private get image() {
    return {
      loading: this.getEditingProduct.thumbnail,
      src: this.getEditingProduct.image,
      id: {} as FilterType,
    };
  }

  private get isOnline() {
    return onlineHelper.isOnline;
  }

  /**
   * Check if the option is available
   */
  private isOptionAvailable(option: SwatchOption): boolean {
    if (option.attribute_code) {
      const currentConfig = Object.assign({}, this.swatchConfiguration);
      currentConfig[option.attribute_code] = option;

      return isOptionAvailableAsync(this.$store, { product: this.getEditingProduct, configuration: currentConfig });
    } else {
      return true;
    }
  }

  private editModeSetFilters(filterOptions: SwatchOption) {
    this.$store.dispatch('extendCart/editModeSetFilters', { filterOptions }).then(() => {
      this.getEditingProduct.image = this.getEditingProduct.configurable_children.find(
        (child) => child[filterOptions.attribute_code].toString() === filterOptions.id,
      ).image;
    });
  }

  private updateProductVariant() {
    this.updateVariant();
    this.closeEditMode();
  }

  /**
   * Updates the cart product with newly selected filters
   */
  private updateVariant() {
    const configuration = {};
    const product = this.getEditingProduct;

    Object.keys(this.getSelectedOptions).forEach((option) => {
      Object.assign(configuration, {
        [option]: {
          id: this.getSelectedOptions[option].id,
          attribute_code: this.getSelectedOptions[option].attribute_code,
          label: this.getSelectedOptions[option].label,
        },
      });
    });

    product.qty = this.getEditingQty;
    this.$store.dispatch('cart/configureItem', { product: product, configuration });
  }

  private closeEditMode() {
    this.$store.dispatch('extendCart/closeEditMode');
    this.broadcast('modal-hide', 'modal-edit-cart-item');
  }
}
