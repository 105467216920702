import { AsVuexStore } from '@zento-lib/components/vuex';

import type { CartState } from '../../../stores/cart/types/CartState';

export const KEY = 'cart';

export type IVSFCart = AsVuexStore<
CartState,
'getCartToken' | 'getLastSyncDate' | 'getLastTotalsSyncDate' | 'getShippingMethod' | 'getPaymentMethod' |
'getLastCartHash' | 'getCurrentCartHash' | 'isCartHashChanged' | 'isSyncRequired' | 'isTotalsSyncRequired' |
'isCartHashEmtpyOrChanged' | 'getCartItems' | 'isTotalsSyncEnabled' | 'isCartConnected' | 'isCartSyncEnabled' |
'getTotals' | 'getItemsTotalQuantity' | 'getCoupon' | 'isVirtualCart',
'disconnect' | 'clear' | 'syncPaymentMethods' | 'syncShippingMethods' | 'sync' | 'serverPull' | 'load' | 'getItem' |
'goToCheckout' | 'addItem' | 'addItems' | 'removeItem' | 'updateQuantity' | 'updateItem' | 'syncTotals' |
'refreshTotals' | 'removeCoupon' | 'applyCoupon' | 'authorize' | 'connect' | 'merge' | 'toggleMicrocart',
'cart/ADD' | 'cart/DEL' | 'cart/DEL_NONCONFIRMED' | 'cart/UPD' | 'cart/LOAD' | 'cart/SHIPPING' | 'cart/SAVE' |
'cart/SAVE_HASH' | 'cart/MARK_SYNC' | 'cart/MARK_TOTALS_SYNC' | 'cart/UPD_PROPS' | 'cart/UPD_TOTALS' |
'cart/SRV_TOKEN' | 'cart/UPD_PAYMENT' | 'cart/TOGGLE_MICROCART' | 'IS_RESTORING'
>;
