import { Component, BaseComponent } from '@zento-lib/components';

import { CmsBlock } from '../../../molecule/CmsBlock/CmsBlock';

import { ICartPayment } from './Payment.d';
import style from './style.scss';

/**
 * Cart Payment Information Section
 **/
@Component({})
export class CartPayment extends BaseComponent<ICartPayment, unknown> {
  render() {
    return (
      <div class={style.cartPaymentMethods}>
        <div class={style.cartPaymentBox}>
          <CmsBlock identifier='payment-methods' key='payment-methods' />
        </div>
      </div>
    );
  }
}
